<template>
    <div class="modal fade" id="exampleModalToggle5" 
    aria-hidden="true" aria-labelledby="exampleModalToggleLabel" tabindex="-1" data-bs-backdrop="static" data-bs-keyboard="false">
    <div class="modal-dialog modal-xl">
      <div class="modal-content">
  
        <div class="modal-body">
          asdsad
        </div>
  
      </div>
    </div>
  </div>
</template>
<script setup>
</script>