<template>
    <div class="theme-options">
        <div id="theme-morning"></div>
        <div id="theme-evening"></div>
    </div>
</template>
<script>
export default{
    name: 'ThemeOptions',
    
}
</script>